.HomeContainer {
    /* background-image: linear-gradient(rgba(66, 9, 126, 0.8), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_A1.png); */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_A5.png); */
    background-position: center center;
    background-size: cover;
    height: 706px;
    padding: 200px 0;
    position: relative;
}
@media (max-width: 991.98px) {
    .HomeContainer {
        height: 400px;
        padding: 100px 0 100px 0;
    }
}

.HomeHeaderContainer {
    position: relative;
}

.Button {
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--button-radius-xxl);
    box-shadow: var(--shadow) var(--shadow-color-light) !important;
    color: #fff;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-md);
    line-height: 1;
    margin-top: var(--margin-lg);
    padding: var(--padding-md2) var(--padding-lg);
    transition: background-color 350ms ease-out, box-shadow 350ms ease-out;
    white-space: nowrap;
}
.Button:hover {
    background-color: var(--primary-color);
    box-shadow:
        0 0 32px rgba(25, 13, 49, 0.7),
        -10px 0 42px rgba(255, 0, 255, 0.35),
        -10px 0 42px rgba(129, 128, 82, 0.4),
        10px 0 42px rgba(0, 255, 255, 0.35) !important;
}

.CartIcon {
    margin-right: var(--margin-md);
}

.ChromaLogo {
    position: absolute;
    bottom: var(--margin-xxl);
    opacity: .4;
    right: var(--margin-xxl);
    width: 140px;
    z-index: 1001;
}

.LogoContainer {
    text-align: center;
    /* padding: 0 var(--padding-xxl); */
    position: absolute;
    z-index: 900;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 991.98px) {
    .LogoContainer {
        top: 86px;
    }
}

.LogoContainer > img {
    height: auto;
    margin-bottom: var(--margin-lg);
    max-width: 100%;
    width: 600px;
}

.SlideLogo {
    display: inline !important;
}

.PoweredBy {
    color: #fff;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-md);
    opacity: 0.3;
    margin: 0 0 var(--margin-xxl) 0;
    text-align: center;
}

@media (max-width: 991.98px) {
    .ChromaLogo {
        display: none !important;
    }
}