.BitcoinIcon {
    color: var(--color-green);
    position: relative;
    font-size: 28px;
    top: -24px;
    margin-left: var(--margin-sm);
}

.BottomText {
    color: rgba(255,255,255,0.4);
    display: block;
    padding: var(--padding-md) 0 0 0;
    text-align: center;
}

.Button {
    padding: 24px 48px !important;
    margin: 0 auto 24px auto !important;
}

@media (max-width: 769px) {
    .Button {
        margin: 0 0 18px 0 !important;
    }
}

.ButtonInLibrary {
    background-color: var(--color-green) !important;
    color: #fff !important;
    padding: 12px 24px !important;
    margin: 0 auto 12px auto !important;
    pointer-events: none;
    cursor: default;
    opacity: .7;
}

.Button.Mui-disabled {
    color: #fff !important;
}

.Button:hover {
    
}

.Button.outlined {
    background-color: none !important;
}

.cardHeader {
    background: var(--color-green) !important;
    padding: 24px !important;
}

.cardSubheader {
    color: rgba(255,255,255,0.6);
    font-weight: 600;
    margin: 0;
}

.cardTitle {
    margin: 0 0 8px 0;
}

.CheckoutButton:link, .CheckoutButton:visited {
    border: 2px solid #fff;
    border-radius: var(--button-radius-sm);
    color: #fff;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-md);
    padding: var(--padding-md) var(--padding-lg);
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out;
}
.CheckoutButton:hover, .CheckoutButton:active {
    background-color: #fff;
    color: var(--primary-color);
}

.CheckoutButtonContainer {
    text-align: center;
    margin-bottom: 73px;
}

.CheckoutIcon {
    margin-right: 8px;
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out;
}

.CurrenciesContainer {
    padding: var(--margin-lg) 0 var(--margin-lg) 0;
    margin: var(--margin-xl) 0 0 0;
}

.CurrencyHeader {
    margin: 0 0 var(--margin-xl) 0;
    text-align: center;
}

.CurrencyIconMystery {
    color: rgba(255,255,255,0.4);
    font-size: 64px;
}

.CurrencyIcon {
    color: rgba(255,255,255,0.4);
    font-size: 64px;
}

.CurrencyIcon:hover {
    color: #fff;
}

.CurrencyIconContainer {
    text-align: center;
    transition: var(--transition-slow);
}

@media (max-width: 769px) {
    .CurrenciesContainer {
        padding: var(--margin-md) 0 0 0;
        margin: var(--margin-xl) 0 0 0;
    }
    .CurrencyIconContainer {
        margin-bottom: 32px;
    }
    .CurrencyIcon, .CurrencyIconMystery {
        font-size: 48px;
    }
}

.CurrencyIconContainer > svg.CurrencyIcon {
    transition: box-shadow .15s ease-in-out, 
        color .15s ease-in-out, 
        scale .15s ease-in-out;
    
    border-radius: 32px;
}

.CurrencyIconContainer > svg.CurrencyIcon:hover {
    box-shadow: var(--shadow-glow-colors);
}

.CurrencyIconContainer:hover {
    transform: scale(1.3);
}

.CurrencyTitle {
    margin: 0 0 var(--margin-md) 0;
}

.CurrencyText {}

.InfoContainer {
    margin: var(--margin-xl) 0 0 0;
}

.price {
    font-size: 64px;
    color: rgba(0,0,0,0.7);
    margin-bottom: 18px;
}

.Prices {
    margin-bottom: var(--margin-xl) !important;
    text-align: center;
}

.Prices > div {
    flex: none !important;
    margin: 0 auto;
    min-width: 75% !important;
}

/* Hiding first and last product for now */
.Prices > div:first-child, .Prices > div:last-child {
    display: none;
    flex: none !important;
    opacity: 0 !important;
    pointer-events: none !important;
    width: 25% !important;
}

.PriceCard {
    box-shadow: var(--shadow-thick) var(--shadow-color-light);
    position: relative;
    transition: var(--transition-fast) !important;
}
@media (min-width: 979px) {
    .PriceCard:hover {
        transform: scale(1.02);
        box-shadow: var(--shadow-thick2) var(--shadow-color-light);
    }
}

.PriceCardFeature {
    box-shadow: var(--shadow-thick) var(--shadow-color-light);
    position: relative;
    transition: var(--transition-fast) !important;
}
@media (min-width: 979px) {
    .PriceCardFeature {
        top: -8px;
    }
    .PriceCardFeature:hover {
        box-shadow: var(--shadow-thick2) var(--shadow-color-light);
        /* transform: scale(1.049); */
        transform: scale(1.02);
    }
}


.ProductsContainer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_A8.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 1200px) {
    .ProductsContent {
        padding: 0 var(--padding-xxl);
    }
}

.ProductsContent {
    padding: var(--padding-xxxl) auto;
}

.ProductFeature {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px 24px !important;
    margin: 8px !important;
    text-align: left !important;
}

.ProductFeaturesContainer {
    width: 100%;
    margin: 0 auto;
}

@media (min-width: 769px) {
    .ProductFeaturesContainer {
        width: 80%;
        margin: 0 auto;
    }
}

.ProductFeaturesContainer li {
    border-radius: 8px;
    padding: 12px 24px !important;
}

.ProductsHero {
    padding-bottom: var(--margin-xxl) !important;
}

.ProductsHeroHeader {
    font-size: var(--font-size-xxl);
    text-align: center;
    margin: var(--margin-xxl) 0 var(--margin-lg) 0;
}

.ProductsHeroText {
    text-align: center !important;
}

.SupportButton {
    background-color: var(--color-white) !important;
    border-radius: var(--button-radius-sm);
    border: none !important;
    color: var(--color-purple) !important;
    font-size: var(--font-size-md) !important;
    font-weight: var(--font-weight-md) !important;
    padding: var(--padding-md) var(--padding-lg) !important;
    position: relative;
    top: 24px;
    transition: var(--transition-slow) !important;
}

.SupportButton:hover {
    background-color: var(--color-white) !important;
    box-shadow: var(--shadow-glow-colors-small) !important;
    transform: scale(1.1);
}

@media (min-width: 979px) {
    .SupportButton {
        float: right;
        padding: var(--padding-md) var(--padding-xl) !important;
    }
}

@media (max-width: 769px) {
    .SupportButton {
        top: 0;
        width: 100%;
    }
}

.SupportContainer {
    border-top: 1px solid rgba(255,255,255,0.2);
    font-size: 18px;
    padding: var(--margin-xl) 0;
    padding: var(--margin-xl) 0;
}

@media (min-width: 769px) {
    .SupportContainer {
        padding: var(--margin-xxl) 0;
        margin-top: var(--margin-xl);
    }
}


@media (max-width: 992px) {
    .SupportInfo {
        margin-bottom: var(--margin-lg);
    }
}

.SupportTitle {
    margin: 0 0 var(--margin-md) 0;
}

.SupportText {
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
}

.SupportIcon {
    color: var(--color-bitcoin) !important;
    font-size: 20px;
    margin: 0 var(--margin-sm2) 0 0;
}

.AlphaAlert {
    background-color: var(--color-warning);
    color: #fff;
    border-radius: var(--button-radius-sm);
    padding: var(--padding-lg);
    margin-bottom: var(--margin-xl);
}

.WarningIcon {
    color: #fff;
    font-size: 19px;
    margin-right: var(--margin-lg);
}