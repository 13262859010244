.description {
    line-height: 1;
}

.listItemContainer {
    background-color: #42424b;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 24px;
    width: 100%;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
}