.AccordianContainer {
    box-shadow: var(--shadow-thick) var(--shadow-color-light);
    position: relative;
    width: 100%;
}

.AccordianHeading {
    flex-basis: '33.33%' !important;
    flex-shrink: 0 !important;
}

.AccordianRoot {
    background-color: #eee !important;
}

.AccordianSecondaryHeading {
    flex-basis: '33.33%' !important;
    flex-shrink: 0 !important;
}

.DetailsButton {
    padding: var(--padding-lg) !important;
    border-radius: var(--button-radius-sm) !important;
}

.DetailsButtonAlt {
    background-color: rgba(111, 12, 216, 0.3) !important;
    border-radius: var(--button-radius-sm) !important;
    color: #fff !important;
    padding: var(--padding-lg) !important;
}

.DetailsButtonAlt:hover {
    background-color: rgba(111, 12, 216, 0.45) !important;
}

.DetailsContainer {
    text-align: center;
}

@media (max-width: 769px) {
    .DetailsContainer {
        margin-bottom: var(--margin-xxl);
    }
}

.DetailsContent {
    text-align: center;
    font-size: var(--font-size-sm2) !important;
}

.DetailsIcon {
    animation: var(--animation-color-rainbow-slow); 
    -webkit-animation: var(--animation-color-rainbow-slow-webkit);
    font-size: 36px;
    margin-bottom: var(--margin-lg);
}

.DetailsHeader {
    border-bottom: 1px solid var(--muted-text-color);
    color: var(--color-dark);
    font-size: var(--font-size-lg) !important;
    font-weight: var(--font-weight-xxl) !important;
    padding-bottom: var(--padding-lg);
    text-align: center;
}

.DetailsTitle {
    color: var(--color-blue);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-md);
    letter-spacing: -0.01em;
}