.avatar {
    width: 100%;
    max-width: 100%;
}

.avatarContainer {
    border: 4px solid #fff;
    border-radius: 100px;
    display: inline-block;
    height: 200px;
    overflow: hidden;
    margin-bottom: var(--padding-lg);
    max-width: 100%;
    width: 200px;
}

.container {
    background-color: #0E0E11;
    border-radius: var(--button-radius-sm);
    padding: var(--padding-lg);
    text-align: center;
}

.joinedIcon {
    margin-right: 8px;
    opacity: .4;
}

.membersince {
    font-size: 16px;
    margin: 0;
}

.seedcode {
    font-size: 16px;
    margin: 0;
}

.userid {
    font-size: 16px;
    opacity: .5;
}

.username {
    font-size: var(--font-size-xl) !important;
    margin: 0;
}