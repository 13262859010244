.Dashboard {
    margin: 18px 0;
}

@media (max-width: 769px) {
    .Dashboard {
        margin: 0 !important;
    }
}

.assets, .products, .transactions {
    margin-bottom: var(--padding-xl);
}

.usersAssets, .usersInfo, .usersProducts, .walletStatus {
    margin-bottom: var(--padding-md);
}

@media (max-width: 769px) {
    .walletStatus {
        margin-bottom: var(--padding-lg);
    }
}