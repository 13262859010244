.Profile {
    margin-top: 56px;
}

.avatar {
    width: 100%;
}

.avatarContainer {
    border: 4px solid #fff;
    border-radius: 100px;
    display: inline-block;
    height: 200px;
    overflow: hidden;
    width: 200px;
}



.Avatar {
    display: inline-block;
    text-align: center;
    margin-bottom: var(--margin-md);
    position: relative;
}

.Avatar .ant-avatar {
    border: 6px solid var(--primary-body-background-color);
    box-sizing: content-box;
}
/* NOT WORKING BELOW */
.dark-theme .Dashboard .avatar .ant-avatar {
    border-color: var(--primary-body-background-color);
}

.Bio {
    margin-top: var(--margin-lg);
}

.Bio p {
    font-size: var(--font-size-sm2);
    line-height: 18px;
}

.CardBody {
    background-color: #0E0E11 !important;
    border-radius: var(--button-radius-sm);
}


.Data p {
    font-size: var(--font-size-sm2);
    margin-bottom: 0;
    text-align: center;
}


.Icons {
    display: flex;
    justify-content: center;
    margin-bottom: var(--margin-md);
}

.Icons a {
    font-size: var(--font-size-md);
    line-height: 1;
    margin: 0 var(--padding-sm);
}

.Icons a:link, .Icons a:visited {
    color: var(--secondary-link-color);
}

.Icons a:hover, .Icons a:active {
    color: var(--secondary-link-hover-color);
    animation: var(--animation-color-fast-rainbow); 
    -webkit-animation: var(--animation-color-rainbow-fast-webkit); /* Chrome and Safari */
}


.Location {
    text-align: center;
}

.Location span {
    color: var(--secondary-link-color);
    margin-left: var(--margin-sm);
}

.Location svg {
    color: var(--secondary-link-hover-color) !important;
}

.IconOnline {
    background-color: green;
    border-radius: 12px;
    border: 3px solid rgba(0,0,0,0.6);
    bottom: 4px;
    box-shadow: var(--shadow) var(--shadow-color-light);
    right: 4px;
    display: inline-block;
    height: 24px;
    position: absolute;
    width: 24px;
}

.IconOffline {
    background-color: red;
    border-radius: 16px;
    border: 3px solid rgba(0,0,0,0.6);
    bottom: 0;
    box-shadow: var(--shadow) var(--shadow-color-light);
    right: 0;
    display: inline-block;
    height: 32px;
    position: absolute;
    width: 32px;
}


.ProfileContainer {
    position: relative;
    top: -172px;
}


.Username {
    font-size: var(--font-size-xl);
    text-align: center;
    margin-bottom: var(--margin-md);
}



