.description {
    line-height: 1;
}

.ethId, .email { 
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.icon {
    margin-right: 8px;
    opacity: .35;
}

.id {
    color:rgba(255,255,255,0.4);
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 12px 0;
}

.link:link h2, .link:visited h2 {
    color: #6f0cd8 !important;
    display: inline-block;
    opacity: .5;
}

.link:hover h2, .link:active h2 {
    opacity: .9;
}

.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 24px;
    width: 100%;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    display: inline-block;
    font-weight: 400;
    font-size: var(--font-size-xl) !important;
    margin: 0 0 12px 0 !important;
    padding: 0 !important;
}