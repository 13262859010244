.description {
    line-height: 1;
}

.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 24px;
    text-align: left;
    width: 100%;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    clear: both;
    font-weight: 400;
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
}

.type {
    float: left;
    margin-bottom: 12px;
    background-color: rgba(255,255,255,0.2) !important;
    font-weight: 500 !important;
}