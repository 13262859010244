.DownloadContainer {
    padding: var(--padding-xxxl) 0;
}

.DownloadInfo {
    background: hsl(240, 8%, 18%);
    padding: var(--padding-xl) var(--padding-xl);
    text-align: left;
}

.DownloadInfo p {
    margin: 0 0 var(--margin-sm) 0;
    padding: 0;
}

.IntroInfo {
    background-color: rgba(255, 255, 255, .15);
    padding: var(--padding-md) var(--padding-md);
}

.LearnContainer {
    padding: 12px !important;
}

.LogoContainer {
    text-align: center;
    padding: 0 var(--padding-xxl);
}

.LogoContainer > img {
    height: auto;
    margin-bottom: var(--margin-lg);
    max-width: 100%;
    width: 600px;
}

.LearnInfo {
    font-size: 18px;
}

.LearnTitle {
    margin: 0 0 var(--margin-sm) 0;
    text-align: left;
}

.IntroTitle {
    font-size: 24px !important;
    font-weight: 400;
    margin: 0 0 var(--margin-sm) 0;
    text-align: center;
}

.LearnText {
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
}

.LearnIcon {
    color:  var(--primary-color-light);
    float: right;
    font-size: 64px;
    margin: 0 var(--margin-lg) 0 0;

}

.VrIcon {
    color:  var(--primary-color-light);
    font-size: 24px;
    margin: 0 var(--margin-md) 0 0;

}

.PoweredBy {
    color: var(--primary-text-color);
    font-size: var(--font-size-md);
    text-align: center;
    opacity: 0.2;
}