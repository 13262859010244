.PurchaseContainer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_A9.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 0 var(--padding-xxl) 0;
}

.Address {
    background-color: var(--color-green-light);
    border-radius: var(--button-radius-sm);
    border-color: #fff;
    display: inline-block;
    word-wrap: break-word;
    line-height: 34px;
    margin: 18px 0 64px 0;
    max-width: 100%;
    padding: var(--padding-md) var(--padding-lg) var(--padding-md) 38px;
    transition: var(--transition-instant);
    
}
.Address > span::selection { background: transparent; color: var(--primary-text-dark-color);}
.Address > span::-moz-selection { background: transparent; color: var(--primary-text-dark-color); }

.Address:active {
    box-shadow: 0px 0px 24px 32px rgba(73, 153, 49, 0.2);
    border: 2px solid #fff;
    transform: scale(1.03);
}

@media (max-width: 767px) {
    .Address > span {
        font-size: 14px;
        line-height: 14px !important;
    }
}

.AddressButton {
    font-size: 10px !important;
    line-height: 1 !important;
    padding: var(--padding-md) var(--padding-sm) !important;
    width: 100%;
}

.AmountProgress {
    height: 24px !important;
    background-color: rgba(0,0,0,0.2) !important;
}

.AmountProgress > div {
    background-color: var(--primary-color-light) !important;
}

.AmountProgressComplete {
    height: 24px !important;
    background-color: rgba(0,0,0,0.2) !important;
}

.AmountProgressComplete > div {
    background-color: var(--color-green) !important;
}

.BtcLightningWarning {
    background-color: var(--color-warning);
}

.BtcLightningWarning p {
    color: #fff !important;
}

.Button {
    float: right;
    margin-left: var(--margin-md) !important;
    padding: var(--padding-md) var(--padding-lg);
}

.Button:first-child {
    margin-left: 0;
}

.ButtonCurrency {
    border: none;
    border-radius: var(--button-radius-sm);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    margin-top: var(--margin-md);
    padding: var(--padding-md) var(--padding-lg);
    position: absolute;
    transition: var(--transition-color-slow);
}

.ButtonCurrency:hover {
    background-color: rgba(0,0,0,0.15);
}

.Complete {
    padding: var(--padding-xl) var(--padding-lg);
    text-align: center;
}

.CompleteIcon {
    color: #fff;
    filter:drop-shadow(0 0 24px var(--primary-color-light));
    font-size: 128px;
    margin-bottom: 24px;
}

.CompleteInfo {
    /* color: var(--color-green) !important; */
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    margin: var(--margin-md) 0 var(--margin-sm);
}

p.ConfirmedInfo {
    text-align: center;
    color: var(--color-blue) !important;
    margin-bottom: 64px !important;
}

.ConfirmingIcon {
    color: var(--primary-color-light);
    /* filter:drop-shadow(0 0 24px var(--primary-color-light)); */
    font-size: 64px;
    margin-bottom: 24px;
}


.CurrencyIcon {
    color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 64px;
}

.CurrencyIcon:hover {
    /* color: var(--color-green-light); */
}

.CurrencyIconSelected .CurrencyIcon {
    color: #fff !important;
}

.CurrencyIconSelected .CurrencyIconButton {
    background-color: var(--primary-color);
}

.CurrencyIconButton {
    background-color: rgba(0,0,0, 0.05);
    border-radius: var(--button-radius-sm);
    cursor: pointer;
    height: 100%;
    padding: var(--padding-lg) var(--padding-md);
}

.CurrencyIconButton:hover {
    background-color: var(--primary-color);
}

.CurrencyIconButton:hover .CurrencyIcon {
    color: #fff;
}

.CurrencyIconContainer {
    text-align: center;
    transition: var(--transition-slow);
}

.CurrencyIconSelected.CurrencyIconContainer p, .CurrencyIconButton:hover p {
    color: #fff !important;
}

.CurrencyIconContainer p {
    font-size: var(--font-size-sm2);
    line-height: 1;
    margin-top: var(--margin-sm2);
}

@media (max-width: 769px) {
    .CurrencyIconContainer {
        padding-left: 0;
        padding-right: 0;
    }

    .CurrencyIconContainer p {
        font-size: var(--font-size-sm);
        margin-top: var(--margin-sm);
    }
}

.CurrencyIconPayment {
    /* color: rgba(0, 0, 0, 0.3); */
    color: var(--color-green);
    font-size: 24px;
    position: relative;
    top: 5px;
    left: -16px;
}

@media (max-width: 769px) {
    .CurrenciesContainer {
        padding: var(--margin-md) 0 0 0;
        margin: var(--margin-lg) 0 0 0;
    }
    .CurrencyIconContainer {
        margin-bottom: 32px;
    }
    .CurrencyIcon {
        font-size: 48px;
    }
}

.CurrencyIconContainer > svg {
    transition: box-shadow .15s ease-in-out, 
        color .15s ease-in-out, 
        scale .15s ease-in-out;
    
    border-radius: 32px;
}

.CurrencyIconContainer > svg:hover {
    /* box-shadow: var(--shadow-glow-colors); */
}

.CurrencyIconContainer:hover {
    /* transform: scale(1.1); */
}

.CurrencySelectContainer {
    margin: var(--margin-xl) 0;
}

@media (max-width: 769px) {
    .CurrencySelectContainer {
        margin: 0;
    }

    .CurrencyIconContainer {
        margin-bottom: var(--margin-md);
    }

    .CurrencyIconButton {
        padding-left: var(--padding-lg);
    }

    .CurrencyIconButton > p {
        display: inline-block;
        float: left;
        font-size: 21px;
        left: 12px;
        line-height: 24px;
        position: relative;
        top: -13px;
    }

    .CurrencyIconButton .CurrencyIcon {
        font-size: var(--font-size-lg);
        float: left;
        position: relative;
        top: -10px;
    }
}

.Failed {
    text-align: center;
}

.Failed p.FailedTitle {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-lg);
    text-align: center;
    color: var(--color-warning) !important;
    margin: var(--margin-sm) 0 0 0;
}

.Failed p.FailedInfo {
    text-align: center;
    margin: 0 0 var(--margin-xxl) 0;
}

.Failed > p.FailedId {
    text-align: center;
    margin: 0;
    color: #ccc !important;
}

.FailedIcon {
    /* filter:drop-shadow(0 0 24px var(--primary-color-light)); */
    font-size: 128px;
    margin: var(--margin-xl) 0 24px 0;
}

.GreenText {
    color: var(--color-green);
    font-weight: 600;
}

.HeaderIcon {
    color: rgba(255, 255, 255, 0.6);
    font-size: var(--font-size-sm2);
    position: relative;
    top: -2px !important;
    margin-right: var(--margin-md);
    margin-left: var(--margin-sm);
}

.HelpButton:link, .HelpButton:visited {
    /* background-color: #fff; */
    border: 1px solid rgba(255, 255, 255, 0.363);
    border-radius: var(--button-radius-sm);
    color: #fff;
    display: block;
    font-weight: var(--font-weight-lg);
    line-height: 1;
    margin-bottom: var(--margin-lg);
    padding: var(--padding-md) var(--padding-lg);
    text-align: center;
    transition: var(--transition-slow);
}

.HelpButton:hover, .HelpButton:active {
    background-color: #fff;
    color: var(--primary-color);
}

.HelpButton > svg {
    left: 8px;
    position: absolute;
    top: 7px;
}

.Loading {
    text-align: center;
}

.Loading p.LoadingInfo {
    text-align: center;
    color: var(--color-blue) !important;
    margin: 0;
}

.LogCard {
    background-color: rgba(255,255,255,0.2) !important;
}

.Logs  {
    margin: 0;
    padding: 0;
}

.Log {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: var(--padding-md);
}

.Log:last-child {
    border-bottom: none;
}

.LogId {
    color: rgba(255, 255, 255, 0.4);
    font-size: 10px;
    letter-spacing: 0.04em;
    line-height: 1;
    margin: 0;
    padding: var(--padding-sm) 0 var(--padding-md) 0;
    text-align: center;
}

.LogId:first-child {
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 0;
}

.LogEmpty {
    color: #fff;
    text-align: center;
    padding: var(--padding-lg);
}

.LogAmount {
    color: #fff;
    display: inline-block;
    margin-bottom: 8px;
}

.LogMessage {
    color: #fff;
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 18px;
}


.LogTitle {
    color: rgba(255, 255, 255, 0.5);
    font-size: var(--font-size-sm);
    line-height: 1;
    margin: 0;
    padding: 0;
}

.LogTitle:first-child {
    margin-top: 0;
}

.ProductCard {
    background-color: #fff !important;
}

.ProductCardBody p {
    color: #333 !important;
}

.ProductCardBody h6 {
    color: var(--color-blue);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-md);
    letter-spacing: -0.01em;
}

.ProductsContainer {
    margin: 0 0 var(--margin-xl) 0;
}

.ProductsHeadline {
    
}

.Product {
    background-color: var(--color-purple);
    border-left: 24px solid rgba(255, 255, 255, 0.3);
    border-radius: var(--button-radius-sm);
    /* box-shadow: var(--shadow-thick) var(--shadow-color-light); */
    margin-bottom: var(--margin-md);
    padding: var(--padding-lg);
    position: relative;
}

.ProductComplete {
    background-color: var(--color-green);
}

.ProductAmount {
    color: #fff;
    float: right;
    font-size: var(--font-size-md);
    font-weight: 800;
    position: relative;
    top: 3px;
    right: 64px;
}

.ProductData {
    color: rgba(255, 255, 255, 0.493);
    font-size: var(--font-size-sm2);
    line-height: 1;
    margin: var(--margin-sm) 0 0 0;
    padding: 0;
}

.ProductIcon {
    margin-right: var(--margin-md);
    color: rgba(255, 255, 255, 0.6) !important;
}

.ProductTitle {
    color: #fff;
    font-size: var(--font-size-md) !important;
    text-align: left !important;
    line-height: 1;
    margin: 0 !important;
}



.PurchaseCard {
    background-color: #fff !important;
    margin-bottom: var(--margin-xl);
}

.ProductCardBody {
    padding: var(--padding-xl) !important;
}

.PurchaseCode {
    padding: var(--padding-sm) !important;
}

.PurchaseCode img {
    max-width: 100%;
}

@media (max-width: 991.98px) {
    .PurchaseCode img {
        width: 100%;
    }
    
    .RightColumn {
        margin-top: var(--margin-lg);
    }
}

.ProductHeadline {
    font-size: var(--font-size-xxl);
    color: #fff !important;
    text-align: center;
    margin: var(--margin-xxl) 0 var(--margin-md) 0;
}

.ProgressCard {
    background-color: #fff !important;
    margin-bottom: var(--margin-sm) !important;
}

.ProgressHeader {
    font-size: var(--font-size-md);
    margin-bottom: var(--margin-md);
}

.QRCode img {
    max-width: 100% !important;
    height: auto !important;
}

.RemoveButton {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 32px;
    top: 30px;
}

.CompleteProductIcon {
    color: rgba(255,255,255,0.5);
    font-size: var(--font-size-lg);
    position: absolute;
    right: 32px;
    top: 30px;
}

.RemoveProductIcon {
    color: rgba(255,255,255,0.3);
    font-size: var(--font-size-lg);
}
.RemoveProductIcon:hover {
    color: rgb(179, 21, 21);
}

.Steps {
    max-width: 600px;
    margin: 0 auto var(--margin-lg) auto;
}

.StepDetails {
    padding: var(--padding-xl) !important;
}

.StepHeader {
    background-color: #eee !important;
    pointer-events: none !important;
}

.StepRoot {
    background-color: #fff !important;
    box-shadow: var(--shadow) var(--shadow-color-xs) !important;
    overflow: hidden;
}

.StepRoot:first-child {
    border-top-left-radius: var(--button-radius-sm) !important;
    border-top-right-radius: var(--button-radius-sm) !important;
}
.StepRoot:last-child {
    border-bottom-left-radius: var(--button-radius-sm) !important;
    border-bottom-right-radius: var(--button-radius-sm) !important;
}

.StepRoot > div:first-child {
    cursor: default !important;
}

.StepTitle {
    color: #333;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-lg);
    margin: 0;
    padding: var(--padding-md);
}

.TotalPrice {
    float: right;
    font-size: var(--font-size-md);
    position: relative;
    top: 0px;
}

.TotalPrice > span {
    /* background-color: #fff; */
    border-radius: var(--button-radius-sm);
    color: var(--color-green);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-xxl);
    padding: var(--padding-md);
    margin-left: var(--margin-sm2);
    position: relative;
    top: 0px;
}

.TxButton:link, .TxButton:visited {
    /* background-color: #fff; */
    border: 1px solid rgba(255, 255, 255, 0.363);
    border-radius: var(--button-radius-sm);
    color: #fff;
    display: block;
    font-weight: var(--font-weight-lg);
    line-height: 1;
    margin-bottom: var(--margin-lg);
    padding: var(--padding-md) var(--padding-lg);
    text-align: center;
    transition: var(--transition-slow);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.TxButton:hover, .TxButton:active {
    background-color: #fff;
    color: var(--primary-color);
}

.TxInfo p {
    font-size: var(--font-size-sm);
    line-height: var(--font-size-sm2);
}

.TxIcon {
    color: var(--primary-color);
    margin-right: var(--margin-md);
}

.TxSpeedInfo {
    color: rgba(0,0,0,0.35);
    font-size: 16px;
    margin: 0 0 24px 0;
    padding: 0;
}

@media (max-width: 769px) {
    .TxSpeedInfo {
        font-size: 12px;
    }
}

.VerificationAlert {
    background-color: var(--color-warning);
    color: #fff;
    border-radius: var(--button-radius-sm);
    padding: var(--padding-lg);
    margin-bottom: var(--margin-lg);
}

.WarningIcon {
    color: #fff;
    font-size: 19px;
    margin-right: var(--margin-lg);
}

.AlphaAlert {
    background-color: var(--color-warning);
    color: #fff;
    border-radius: var(--button-radius-sm);
    padding: var(--padding-lg);
    margin-bottom: var(--margin-xl);
}

.WarningIcon {
    color: #fff;
    font-size: 19px;
    margin-right: var(--margin-lg);
}